<template>
  <section class="about__page" style="height: 100%;">
    <nav
      v-if="this.$route.meta.subpage !== true"
      class="container about__page_nav d-flex justify-center align-center pt-3 col-12 col-md-8 flex-nowrap"
    >
      <v-col
        class="about__page_nav-link d-flex justify-center flex-column align-center"
      >
        <router-link :to="{ name: 'projeto' }">
          O que é
        </router-link>
      </v-col>
      <v-col class="about__page_nav-link">
        <router-link :to="{ name: 'mapa_comunitario' }">
          Mapas Comunitários
        </router-link>
      </v-col>
      <v-col class="about__page_nav-link">
        <router-link :to="{ name: 'ficha_tecnica' }">
          Ficha Técnica
        </router-link>
      </v-col>
      <v-col class="about__page_nav-link">
        <router-link :to="{ name: 'projeto_parceiros' }">
          Parceiros
        </router-link>
      </v-col>
    </nav>

    <transition>
      <router-view />
    </transition>
    <ScrollTop />
  </section>
</template>

<script>
import ScrollTop from "../components/ScrollTop.vue";
export default {
  components: { ScrollTop },
  data() {
    return {
      active: "active",
    };
  },
  created() {},
};
</script>

<style scoped>
a {
  color: #e06919;
}

.router-link-exact-active {
  background-image: url("../modules/Projeto/assets/link_bottom.png");
}
</style>
